import * as React from "react";

const Test = ({ heroTitle, description }) => {
  return (
    <section className="py-5 text-center container">
      <div className="row py-lg-5">
        <div className="col-lg-12 col-md-8 mx-auto">
          <h1 className="fw-light">{heroTitle}</h1>
        </div>
        <div className="col-lg-12 col-md-8 mx-auto">
          <h1 className="fw-light">{description}</h1>
        </div>
      </div>
    </section>
  )
}

export default Test;
